import React from 'react';
import './styles/screen.css';
import {useState, useEffect} from 'react';
import Switch, {Case} from './components/Switch';
import SuccessState from './components/SuccessState';
import ErrorState from './components/ErrorState';
import LoadingState from './components/LoadingState';
import {ReactComponent as StripeLogo} from './assets/stripe_verified.svg';
import {ReactComponent as GhostStripe} from './assets/ghost_stripe.svg';

/**
 * @param {object} props
 * @param {() => Promise<string>} props.getData
 */
function App(props) {
    const {getData} = props;
    const [state, setState] = useState({status: 'init', data: ''});

    useEffect(() => {
        setState({status: 'loading', data: ''});
        getData().then(
            token => setState({status: 'success', data: token}),
            error => setState({status: 'error', data: error.message})
        );
    }, [getData]);

    return (
        <div className="gh-viewport">
            <main className="gh-main">
                <div className="gh-page">
                    <section className="gh-container gh-content">

                        <div className="gh-header">
                            <GhostStripe className="gh-logo" />
                        </div>

                        <Switch on={state.status}>
                            <Case when="loading">
                                <LoadingState />
                            </Case>
                            <Case when="error">
                                <ErrorState errorMessage={state.data} />
                            </Case>
                            <Case when="success">
                                <SuccessState token={state.data} />
                            </Case>
                        </Switch>

                        <div className="gh-support">
                            <p>Have a question? <a className="gh-link" href="https://ghost.org/integrations/stripe/" target="_blank" rel="noopener noreferrer">Get support</a></p>
                        </div>

                    </section>

                    <div className="gh-footer">
                        <StripeLogo />
                    </div>

                </div>
            </main>
        </div>
    );
}

export default App;
