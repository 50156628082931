import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import {useState} from 'react';
import {ReactComponent as Arrow} from '../assets/arrow_right.svg';

/**
 * @param {object} props
 * @param {string} props.token
 */
function SuccessState(props) {
    const [copied, setCopied] = useState(false);

    return (
        <div className="gh-box">
            <h1>Secure key generated.</h1>
            <p>Paste this key into Ghost Admin to complete the connection to Stripe. This is a secret key, so you should never give it to anyone else!</p>
            <code>{props.token}</code>
            <CopyToClipboard text={props.token} onCopy={() => setCopied(true)}>
                <button className="gh-button gh-button-primary">
                    <span>
                        {copied ? 'Copied to clipboard!' : 'Copy secure key'}
                        {copied ? null : <Arrow />}
                    </span>
                </button>
            </CopyToClipboard>
        </div>
    );
}

export default SuccessState;
